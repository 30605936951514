import { ChakraProvider } from '@chakra-ui/react';
import * as React from "react";
import { theme } from './src/styles/theme';
import { UserProvider } from './src/context/user';

export const wrapPageElement = ({ element }) => (
  <ChakraProvider theme={theme}>
    <UserProvider>
      {element}
    </UserProvider>
  </ChakraProvider>
);