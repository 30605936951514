export const components = {
  Heading: {
    baseStyle: {
      fontWeight: `bold`,
      mb: 4,
    }, 
    sizes: {
      md: {
        fontSize: '1.25rem',
        textTransform: 'uppercase'
      },
    } 
  },
  Button: {
    baseStyle: {
      padding: `0rem`,
      borderRadius: `0rem`,
      fontWeight: `bold`,
      textTransform: `uppercase`
    },
    sizes: {
      md: {
        fontSize: 'lg',
        h: `3.25rem`,
        p: `1 10`,
        pe: 10,
        ps: 10,
      }
    },
    variants: {
      primary: {
        color: `green.100`,
        bgGradient: 'linear(45deg, green.100 0%, green.500 75%)',
        clipPath: `polygon(
          0% 0%, 
          0% 0%, 
          calc(100% - 1.05rem) 0%, 
          100% 1.05rem, 
          100% 100%, 
          100% 100%, 
          1.05rem 100%, 
          0% calc(100% - 1.05rem)
        )`,
        _before: {
          content: '""',
          display: 'block',
          position: 'absolute',
          top: '.125rem',
          left: '.125rem',
          w: 'calc(100% - .25rem)',
          h: 'calc(100% - .25rem)',
          background: 'gray.800',
          clipPath: `polygon(
            0% 0%, 
            0% 0%, 
            calc(100% - 1rem) 0%, 
            100% 1rem, 
            100% 100%, 
            100% 100%, 
            1rem 100%, 
            0% calc(100% - 1rem)
          )`,
          zIndex: -1
        },
        _hover: {
          _disabled: {
            bgGradient: 'linear(45deg, green.100 0%, green.500 75%)',
          }
        }
      }
    }
  },
  Link: {
    variants: {
      underline: {
        fontWeight: '600',
        color: `green.100`,
        textTransform: 'uppercase',
        lineHeight: 1,
        borderBottom: 1,
        borderBottomStyle: 'solid',
        borderColor: 'white',
        _hover: {
          textDecoration: 'none'
        }
      }
    }
  },
};
