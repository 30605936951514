export const styles = {
  global: {
    body: {
      color: 'green.100',
      bg: 'black',
      maxWidth: '50rem',
      m: 'auto'
    }
  }
}
