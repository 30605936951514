import React, { useReducer } from "react";

const isBrowser = typeof window !== "undefined";

const initialState = {
  badges: (isBrowser ? JSON.parse(window.localStorage.getItem('badges')) : null) || []
};

function reducer(state, action) {
  switch (action.type) {
    case 'ADD_BADGE':
      if (state.badges.indexOf(action.id) === -1) {
        const badges = [...state.badges, action.id];
        if (isBrowser) {
          window.localStorage.setItem('badges', JSON.stringify(badges));
        }
        return { ...state, badges };
      } else {
        return state;
      }
    default:
      throw new Error();
  }
};

export const UserContext = React.createContext({
  userBadges: initialState.badges
});

export const UserProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const value = {
    userBadges: state.badges,
    addBadge: (id) => {
      dispatch({ type: 'ADD_BADGE', id });
    }
  }

  return (
    <UserContext.Provider value={value}>
      {children}
    </UserContext.Provider>
  );
};